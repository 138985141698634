import React from "react";
import { useTranslation } from "react-i18next";
import {
  HiOutlineAdjustmentsHorizontal,
  HiOutlineHomeModern,
  HiOutlineQuestionMarkCircle,
} from "react-icons/hi2";
import config from "config";
import { letters } from "utils/display";
import { Link, Outlet } from "react-router-dom";

const WIDTH = 48;

const BOTTOM_PAGES = [
  {
    name: "Settings",
    href: "/settings",
    icon: HiOutlineAdjustmentsHorizontal,
  },
  {
    name: "Support",
    href: "/support",
    icon: HiOutlineQuestionMarkCircle,
  },
];

function Sidebar({}: {}) {
  const { t } = useTranslation();

  const PAGES = [
    {
      name: "Dashboard",
      href: "/dashboard",
      icon: HiOutlineHomeModern,
    },
    // {
    //   name: 'Admin View',
    //   href: '/admin',
    //   icon: BsSpeedometer2,
    // },
  ];

  // .map((page) => ({ href: `/${page.name}`, ...page }));

  return (
    <div className="drawer drawer-mobile">
      <input id="my-drawer-2" type="checkbox" className="drawer-toggle" />
      <div className="drawer-content">
        <label
          htmlFor="my-drawer-2"
          className="drawer-button lg:hidden btn btn-sm"
        >
          Open Drawer
        </label>
        <Outlet />
      </div>{" "}
      <div className="drawer-side bg-base-200">
        <label htmlFor="my-drawer-2" className="drawer-overlay"></label>
        <ul className={`menu p-4 w-${WIDTH} text-base-content`}>
          <li>
            <Link to={`/dashboard`}>
              <div className="flex flex-row align-middle">
                <div className="avatar placeholder">
                  <div className="bg-neutral-focus text-neutral-content mask mask-squircle w-8">
                    <span className="text-xs capitalize">
                      {letters(config.platform.name ?? "")}
                    </span>
                  </div>
                </div>
                <div className="pl-2 self-center capitalize">
                  {config.platform.name}
                </div>
              </div>
            </Link>
          </li>
          <div className="divider"></div>
          {PAGES.map((page) => (
            <li key={page.name}>
              <Link to={`${page.href}`} className={false ? `active` : ``}>
                <page.icon className="h-6 w-6" />
                {t(page.name)}
              </Link>
            </li>
          ))}
        </ul>
        <ul className={`menu p-4 w-${WIDTH} text-base-content justify-end`}>
          <div className="divider"></div>
          {BOTTOM_PAGES.map((page) => (
            <li key={page.name}>
              <Link
                to={page.href}
                target={page.href.startsWith("http") ? "_blank" : "_self"}
                className={false ? `active` : ``}
              >
                <page.icon className="h-6 w-6" />
                {t(page.name)}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default Sidebar;
