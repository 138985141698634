import { useAuth } from "context/AuthProvider";
import React, { useRef } from "react";
import { supabase } from "services/supabase/supabase-frontend";

// define a type for stripe-pricing-table so typescript doesn't complain
declare global {
  namespace JSX {
    interface IntrinsicElements {
      "stripe-pricing-table": any;
    }
  }
}

export function UploadButton({ startUpload }: { startUpload: () => void }) {
  const el = useRef<null | HTMLDivElement>(null);

  const { user } = useAuth();
  const [loading, setLoading] = React.useState(false);
  const [showPlans, setShowPlans] = React.useState(false);
  const [companyId, setCompanyId] = React.useState<string | null>(null);
  const checkStatus = async () => {
    setLoading(true);

    // TODO: why is the company sometimes null on the user from auth?
    const { data: userRow } = await supabase
      .from("users")
      .select("companies (id)")
      .filter("id", "eq", user?.id)
      .single();

    const company = userRow?.companies as any;
    const companyId = company?.id;

    if (!companyId) {
      alert("An error occurred");
      setLoading(false);
      return;
    }
    setCompanyId(companyId);

    const { data, error } = await supabase
      .from("companies")
      .select("stripe_subscription_id")
      .single();
    if (!data?.stripe_subscription_id) {
      setShowPlans(true);
      setTimeout(() => {
        el?.current?.scrollIntoView({ behavior: "smooth" });
      }, 2);
    }
    if (error) {
      alert("An error occurred");
      setLoading(false);
      return;
    }
    await startUpload();
    setLoading(false);
  };
  if (showPlans) {
    return (
      <div className="w-full h-[620px]">
        <div className="text-xl mt-8 p-4" ref={el}>
          Please select a plan to finalize your upload
        </div>
        <stripe-pricing-table
          pricing-table-id="prctbl_1NLqueLvQ1dv0SFtzH00UqMQ"
          publishable-key="pk_test_51NGVPFLvQ1dv0SFt54rWKvJg08jx1511dQMnkLXZQVdtE7Mm9VybILd8FkUb7JOqp8mVcLyIPZm9ylIe7nJifRcx00ApYD6kcc"
          client-reference-id={companyId}
          customer-email={user?.email}
        ></stripe-pricing-table>
      </div>
    );
  }

  return (
    <button
      className="btn btn-primary mt-4 mb-32 w-72 hover:bg-gray-800"
      onClick={() => checkStatus()}
      disabled={loading}
    >
      {loading ? <span className="loading loading-spinner"></span> : ""}
      Upload
    </button>
  );
}
