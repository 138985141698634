import { useAuth } from "context/AuthProvider";
import { useEffect } from "react";
import { supabase } from "services/supabase/supabase-frontend";

export default function Status() {
  const { user } = useAuth();

  useEffect(() => {
    const getSummary = async () => {
      const { error, data } = await supabase
        .from("summary_groups")
        .select("*, summary_files (*)");
      console.log(data);
      if (error) {
        alert("An error occurred");
        return;
      }
    };
    getSummary();
  }, []);

  return (
    <div>
      <h1>Status</h1>
    </div>
  );
}
