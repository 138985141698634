import { BrowserRouter } from "react-router-dom";
import AuthProvider from "./context/AuthProvider";

import AppRoutes from "AppRoutes";
import "./index.css";

export default function App() {
  return (
    <>
      <BrowserRouter>
        <AuthProvider>
          <AppRoutes />
        </AuthProvider>
      </BrowserRouter>
    </>
  );
}
