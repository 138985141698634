import { Route, Routes } from "react-router-dom";
import AuthRoute from "./components/AuthRoute";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import Settings from "./pages/Settings";
import Pricing from "./pages/Pricing";
import Support from "./pages/Support";
import Status from "./pages/Status";
import NavBar from "components/Navbar";
import Sidebar from "components/Sidebar";
import Success from "pages/Success";

const AppRoutes = () => {
  return (
    <>
      <Routes>
        <Route element={<AuthRoute />}>
          <Route element={<Sidebar />}>
            <Route element={<NavBar />}>
              <Route path="/" element={<Home />} />

              <Route path="/dashboard" element={<Home />} />
              <Route path="/success" element={<Success />} />
              <Route path="/settings" element={<Settings />} />
              <Route path="/pricing" element={<Pricing />} />
              <Route path="/status" element={<Status />} />
              <Route path="/support" element={<Support />} />
            </Route>
          </Route>
        </Route>
        <Route path="/signup" element={<Signup />} />
        <Route path="/login" element={<Login />} />
      </Routes>
    </>
  );
};

export default AppRoutes;
