import { useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthProvider";
import React from "react";

const url =
  "https://images.unsplash.com/photo-1499951360447-b19be8fe80f5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80";

const Signup = () => {
  const [email, setEmail] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [checkEmail, setCheckEmail] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { login, user } = useAuth();
  const [checked, setChecked] = React.useState(true);

  const name = user?.user_metadata.name || "";

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setErrorMsg("");
    setLoading(true);
    if (!email) {
      setErrorMsg("Please fill in the fields");
      return;
    }
    if (!login) {
      return;
    }
    const {
      data: { user, session },
      error,
    } = await login(email);
    if (error) {
      setErrorMsg(error.message);
    } else if (user && session) {
      return navigate("/");
    } else {
      setCheckEmail(true);
    }
    setLoading(false);
  };

  const form = (
    <form className="space-y-5" onSubmit={handleSubmit}>
      <p className="text-gray-500">Please sign in to your account.</p>
      <div className="space-y-2">
        <label className="text-sm font-medium text-gray-700 tracking-wide">
          Email
        </label>
        <input
          disabled={loading || checkEmail}
          autoFocus
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          className="w-full input"
          type="email"
          placeholder="you@company.com"
        />
      </div>
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <input
            onChange={() => setChecked(!checked)}
            id="remember_me"
            name="remember_me"
            disabled={loading || checkEmail}
            checked={checked}
            type="checkbox"
            className="h-4 w-4 bg-blue-500 focus:ring-blue-400 border-gray-300 rounded"
          />
          <label
            htmlFor="remember_me"
            className="ml-2 block text-sm text-gray-800"
          >
            Remember me
          </label>
        </div>
      </div>
      <button
        type="submit"
        className={`w-full flex justify-center btn btn-primary ${
          loading ? "loading" : ""
        }
                    `}
        disabled={loading}
      >
        Sign in
      </button>
    </form>
  );

  let content = form;
  if (errorMsg && errorMsg.includes("Signups not allowed")) {
    content = (
      <>
        {form}
        <div className="alert mt-4">
          Email not found. New?{" "}
          <Link to="/signup" className="text-blue-500 hover:underline">
            Sign up
          </Link>{" "}
          instead!
        </div>
      </>
    );
  } else if (errorMsg) {
    content = (
      <>
        {form}
        <div className="alert alert-error">{errorMsg}</div>
      </>
    );
  } else if (checkEmail) {
    content = (
      <div className="flex items-center justify-center">
        Email sent! Please check your email at {email} for a link to login
      </div>
    );
  }

  return (
    <div
      className="bg-no-repeat bg-cover bg-center relative"
      style={{
        backgroundImage: `url(${url})`,
      }}
    >
      <div className="absolute bg-gradient-to-b from-gray-700 to-gray-400 opacity-75 inset-0 z-0"></div>
      <div className="min-h-screen sm:flex sm:flex-row mx-0 justify-center">
        <div className="flex-col flex self-center p-2 sm:max-w-5xl xl:max-w-2xl z-10">
          <div className="self-start hidden lg:flex flex-col text-white">
            <h1 className="mb-3 font-bold text-5xl">Welcome to Res Ipsa</h1>
            <p className="pr-3">
              Lorem ipsum is placeholder text commonly used in the graphic,
              print, and publishing industries for previewing layouts and visual
              mockups
            </p>
          </div>
        </div>
        <div className="flex justify-center self-center z-10">
          <div className="p-12 bg-white mx-auto rounded-2xl w-100 ">
            <div className="mb-4">
              <h3 className="font-semibold text-2xl text-gray-800">Sign Up</h3>
            </div>
            {content}

            <div className="pt-5 text-center text-gray-400 text-xs">
              <span>
                Copyright © 2021-{new Date().getFullYear()}{" "}
                <a
                  href="https://codepen.io/uidesignhub"
                  rel="noopener noreferrer"
                  target="_blank"
                  title="Ajimon"
                  className="text-green hover:text-green-500 "
                >
                  {name}
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
