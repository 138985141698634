import mammoth from "mammoth/mammoth.browser";

enum SupportedFileTypes {
  DOCX = "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  TXT = "text/plain",
}

// FileToText extracts the text from a word or text `File` type
export class FileToText {
  private file: File;
  fileType: SupportedFileTypes;

  constructor(file: File) {
    this.file = file;
    this.fileType = this.getType(file);
  }

  getText = async (): Promise<string> => {
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onload = async (event) => {
        try {
          let text: string;
          if (this.fileType === SupportedFileTypes.TXT) {
            text = event?.target?.result as string;
          } else {
            text = await this.extractRawText(reader.result);
          }
          return resolve(text);
        } catch (err) {
          return reject(err);
        }
      };
      reader.onerror = (err) => {
        return reject(err);
      };
      try {
        if (this.fileType === SupportedFileTypes.TXT) {
          reader.readAsText(this.file);
        } else {
          reader.readAsArrayBuffer(this.file);
        }
      } catch (err) {
        return reject(err);
      }
    });
  };

  async extractRawText(result: ArrayBuffer | null | string) {
    const resp = await mammoth.extractRawText({ arrayBuffer: result });
    return resp.value as string;
  }

  getType(file: File): SupportedFileTypes {
    const fileType = file.type;
    if (
      fileType ===
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ) {
      return SupportedFileTypes.DOCX;
    } else if (fileType === "text/plain") {
      return SupportedFileTypes.TXT;
    }
    throw new Error(`File type ${fileType} not supported.`);
  }
}
