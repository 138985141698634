import { createClient } from "@supabase/supabase-js";
import appConfig from "config";
import { v4 as uuidv4 } from "uuid";

if (
  !process.env.REACT_APP_SUPABASE_URL ||
  !process.env.REACT_APP_SUPABASE_ANON_KEY
) {
  throw new Error(
    "Missing SUPABASE_ANON_KEY or REACT_APP_SUPABASE_URL env var"
  );
}

export const supabase = createClient(
  process.env.REACT_APP_SUPABASE_URL,
  process.env.REACT_APP_SUPABASE_ANON_KEY
);

export async function uploadLogo(userId: string, file: File) {
  const filename = `${uuidv4()}-${file.name}`;
  await supabase.storage
    .from(appConfig.bucketName)
    .upload(`${userId}/${filename}`, file, {
      cacheControl: "3600",
      upsert: false,
    });
  return filename;
}

export async function uploadFile(userId: string, fileId: string, file: File) {
  await supabase.storage
    .from(appConfig.bucketName)
    .upload(`${userId}/${fileId}`, file, {
      cacheControl: "3600",
      upsert: false,
    });
}
