import FileTable from "components/FileTable";
import { LogoUploader } from "components/LogoUploader";
import config from "config";
import { useAuth } from "context/AuthProvider";
import React from "react";
import { useDropzone } from "react-dropzone";
import { HiOutlineFolder } from "react-icons/hi2";
import {
  createSummaryGroup,
  createPrepFiles,
  getChunks,
} from "services/file-parsing/prep-files";
import { Chunk, PrepFile, PrepFileStatus } from "services/file-parsing/types";
import { UploadButton } from "./UploadButton";

export default function Home() {
  const date = new Date();
  const defaultDate = new Intl.DateTimeFormat("en-US", {
    month: "long",
    day: "numeric",
    year: "numeric",
  }).format(date);

  const { user } = useAuth();
  const [prepFiles, setPrepFiles] = React.useState<PrepFile[]>([]);
  const [error, setError] = React.useState<string | null>(null);
  const [ready, setReady] = React.useState(false);
  const [done, setDone] = React.useState(false);
  const [coverPageDate, setCoverPageDate] = React.useState<string>(defaultDate);
  const [uploading, setUploading] = React.useState(false);
  const [logoFileId, setLogoFileId] = React.useState<string | undefined>(
    undefined
  );
  const [includeCoverPage, setIncludeCoverPage] = React.useState(true);
  const onDropAccepted = async (newFiles: File[]) => {
    setError(null);
    if (prepFiles.length + newFiles.length > config.maxFilesLimit) {
      setError(
        `You can only upload ${config.maxFilesLimit} files at a time. Please remove some files and try again.`
      );
      return;
    }
    const newprepFiles = createPrepFiles(newFiles);
    setPrepFiles((prevprepFiles) => [...prevprepFiles, ...newprepFiles]);
    checkFiles(newprepFiles);
  };

  const onNameChange = (id: string, newName: string) => {
    setPrepFiles((prevprepFiles) =>
      prevprepFiles.map((fileStatus) => {
        if (fileStatus.id === id) {
          return {
            ...fileStatus,
            displayName: newName,
          };
        }
        return fileStatus;
      })
    );
  };

  async function checkFiles(newprepFiles: PrepFile[]) {
    if (!user) {
      return;
    }
    for (let i = 0; i < newprepFiles.length; i++) {
      const file = newprepFiles[i];
      if (!file) {
        continue;
      }
      let status = PrepFileStatus.SUCCESS;
      let chunks: Chunk[] = [];
      let isDeposition = false;
      try {
        const resp = await getChunks(user?.id, file);
        chunks = resp.chunks;
        isDeposition = resp.isDeposition;
      } catch (e) {
        console.error(e);
        status = PrepFileStatus.ERROR;
      }
      setPrepFiles((prevprepFiles) =>
        prevprepFiles.map((fileStatus) => {
          if (fileStatus.id === file.id) {
            return {
              isDeposition,
              ...fileStatus,
              chunks,
              status,
            };
          }
          return fileStatus;
        })
      );
    }
    setReady(true);
  }

  const startUpload = async () => {
    if (!user) {
      return;
    }
    setUploading(true);
    try {
    
      await createSummaryGroup(
        prepFiles,
        includeCoverPage,
        logoFileId,
        coverPageDate
      );
      setDone(true);
    } catch (e: any) {
      console.error(e);
      setError(e.message);
    } finally {
      setUploading(false);
    }
  };

  const reset = () => {
    setPrepFiles([]);
    setReady(false);
    setDone(false);
    setUploading(false);
    setError(null);
  };

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: config.maxFilesLimit,
    onDropAccepted,
    accept: {
      "text/plain": [".docx", ".doc", ".txt"],
    },
  });

  return (
    <>
      <div className="items-center justify-center flex flex-col text-center mt-8 mb-4">
        <h1 className="text-2xl font-bold text-center">Create Summaries</h1>
        <div className="divider w-2/3 self-center" />
        {!!prepFiles.length && (
          <>
            <FileTable
              disabled={uploading || done}
              onRemoveFile={(id: string) => {
                setPrepFiles(prepFiles.filter((file) => file.id !== id));
                setError(null);
              }}
              files={prepFiles}
              onNameChange={onNameChange}
            />
          </>
        )}
        {!done && prepFiles.length < config.maxFilesLimit && (
          <div
            className={`flex flex-col items-center justify-center w-1/2 mt-2`}
          >
            <div
              {...getRootProps({ className: "dropzone" })}
              className="container flex justify-center w-full h-32 px-4 py-6 border-2 border-gray-300 rounded-md items-center text-center border-dashed hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <input {...getInputProps()} />
              <button className="flex flex-col items-center justify-center w-full ">
                <div className="flex flex-row items-center justify-center text-center">
                  <HiOutlineFolder className="h-6 w-6 mr-4" />
                  <strong>
                    {!prepFiles.length
                      ? "Click to add a file for summary"
                      : "Click to add more files to the upload"}
                  </strong>
                </div>
                <div className="text-center mt-2 text-xs">
                  Files must be in .docx or .txt format to ensure the best
                  summary results.
                </div>
                <div className="text-center mt-2 text-xs">
                  Upload up to {config.maxFilesLimit} files at a time.
                </div>
              </button>
            </div>
          </div>
        )}

        {!done && prepFiles.length > 0 && (
          <>
            <div className="divider mt-6 w-2/3 self-center"></div>
            <div className={`flex flex-col  w-1/2 mt-4 text-left`}>
              {/* <h1 className="text-2xl font-bold mb-4 text-center">
                Add Cover Page
              </h1> */}
              <div className="alert shadow-lg mb-4">
                <div className="">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    className="stroke-info flex-shrink-0 w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    ></path>
                  </svg>
                  <div>
                    <h3 className="font-bold">Cover Page Settings</h3>
                    <div className="text-xs mt-2">
                      Beside each document you've uploaded, you can insert a
                      name for the document. On the cover page, it will appear
                      just as you enter it.
                    </div>
                    <div className="text-xs mt-4">
                      The date will appear on each document and you may upload
                      your firm logo to appear on the cover page if you'd like.
                    </div>
                    <div className="text-xs mt-4">
                      If you do not want a cover page, uncheck the "cover page"
                      box .
                    </div>
                  </div>
                </div>
              </div>
              <div className="mb-4 font-light text-sm "></div>
              <div className="form-control mb-4">
                <label className="cursor-pointer label justify-normal">
                  <input
                    type="checkbox"
                    checked={includeCoverPage}
                    onChange={(e) => setIncludeCoverPage(e.target.checked)}
                    className="checkbox checkbox-lg"
                  />
                  <span className="ml-2 label-text">Include Cover Page</span>
                </label>
              </div>
              <LogoUploader
                disabled={uploading || done}
                show={includeCoverPage}
                onFileIdChange={(fileId: string | undefined) =>
                  setLogoFileId(fileId)
                }
              />
              {/* date of summary */}
              {includeCoverPage && (
                <div className="form-control  text-center mt-4 mb-8">
                  <label className="label">
                    <span className="label-text">Date of Summary</span>
                  </label>
                  <input
                    type="text"
                    className="input input-bordered"
                    value={coverPageDate}
                    onChange={(e) => setCoverPageDate(e.target.value)}
                  />
                </div>
              )}
            </div>
          </>
        )}

        {error && (
          <div className="alert alert-error mt-4">
            <div className="flex-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-alert-triangle"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="#ffffff"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" />
                <path d="M12 9v2m0 4v.01" />
                <path d="M19 15a8 8 0 1 0 -14 0" />
              </svg>
              <label>{error}</label>
            </div>
          </div>
        )}
        {ready && !done && (
          <>
            <UploadButton startUpload={startUpload} />
          </>
        )}
        {/* <UploadButton startUpload={startUpload} /> */}
        {done && (
          <>
            <div className="alert alert-success mt-4 justify-center">
              Files uploaded! 🎉 It will take a minute or two for them to show
              up in your inbox.
            </div>
            <button className="btn btn-primary mt-4 " onClick={() => reset()}>
              Upload more
            </button>
          </>
        )}
      </div>
    </>
  );
}
