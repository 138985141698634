import { useAuth } from "context/AuthProvider";

export default function Home() {
  const { user } = useAuth();
  console.log(user?.company);
  return (
    <div>
      <h1 className="text-xl">Support</h1>
    </div>
  );
}
