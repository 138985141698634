export default function Pricing() {
  return (
    <div>
      <h1 className="text-xl">Pricing</h1>
      <stripe-pricing-table
        pricing-table-id="prctbl_1NLqueLvQ1dv0SFtzH00UqMQ"
        publishable-key="pk_test_51NGVPFLvQ1dv0SFt54rWKvJg08jx1511dQMnkLXZQVdtE7Mm9VybILd8FkUb7JOqp8mVcLyIPZm9ylIe7nJifRcx00ApYD6kcc"
        // client-reference-id={companyId}
        // customer-email={user?.email}
      ></stripe-pricing-table>
    </div>
  );
}
