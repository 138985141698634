import { User } from "@supabase/supabase-js";
import { createContext, useContext, useEffect, useState } from "react";
import { supabase } from "services/supabase/supabase-frontend";

type UserWithCompany = User & {
  company?: {
    id: string;
    name: string;
    stripe_subscription_status: string;
  };
};

interface AuthContextValues {
  user: UserWithCompany | null;
  auth: boolean;
  login?: (email: string) => Promise<any>;
  signOut?: () => Promise<any>;
  loaded: boolean;
}

const AuthContext = createContext<AuthContextValues>({
  user: null,
  auth: false,
  loaded: false,
});

export const useAuth = () => useContext(AuthContext);

const login = async (email: string) =>{
  return supabase.auth.signInWithOtp({
    email,
    options: {
      data: {
        full_name: "Test",
        company_name: "Test",
      },
    },
    // options: {
    //   shouldCreateUser: false,
    // },
  });

}

const signOut = () => supabase.auth.signOut();

const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [auth, setAuth] = useState(false);
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    const getUser = async () => {
      const { data } = await supabase.auth.getUser();
      const { user: currentUser } = data;
      const { data: userRow } = await supabase
        .from("users")
        .select("companies (*)")
        .filter("id", "eq", currentUser?.id)
        .single();
      const user = currentUser
        ? { ...currentUser, company: userRow?.companies }
        : null;
      setUser(user);
      setAuth(currentUser ? true : false);
      setLoading(false);
    };
    getUser();
    const { data } = supabase.auth.onAuthStateChange(async (event, session) => {
      if (event === "SIGNED_IN") {
        return getUser();
      } else if (event === "SIGNED_OUT") {
        setAuth(false);
        setUser(null);
      }
    });
    return () => {
      data.subscription.unsubscribe();
    };
  }, []);

  return (
    <AuthContext.Provider
      value={{
        loaded: !loading,
        auth,
        user,
        login,
        signOut,
      }}
    >
      {!loading && children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
