import { FileToText } from "services/file-parsing/FileToText";
import { supabase, uploadFile } from "services/supabase/supabase-frontend";
import { v4 as uuid } from "uuid";
import { Chunk, PrepFile, PrepFileStatus } from "./types";
import { TextChunker } from "./TextChunker";

export function createPrepFiles(files: File[]): PrepFile[] {
  return files.map((file) => ({
    id: uuid(),
    displayName: withoutFileEnding(file.name),
    size: file.size,
    file: file,
    fileType: file.type,
    status: PrepFileStatus.IN_PROGRESS,
  }));
}

export async function getChunks(
  userId: string,
  inputFile: PrepFile
): Promise<{ isDeposition: boolean; chunks: Chunk[] }> {
  const handler = new FileToText(inputFile.file);
  const text = await handler.getText();
  const chunker = new TextChunker(text);
  const chunks = chunker.getChunks();
  if (!chunker.isDeposition) {
    await uploadFile(userId, inputFile.id, inputFile.file);
  }
  return {
    isDeposition: chunker.isDeposition,
    chunks,
  };
}

export async function createSummaryGroup(
  prepFiles: PrepFile[],
  includeCoverPage?: boolean,
  coverPageLogoUrl?: string,
  coverPageDate?: string
) {
  const p_files = prepFiles.map((file) => ({
    fileId: file.id,
    fileName: file.displayName,
    isDeposition: file.isDeposition,
    chunks: file.chunks?.map((chunks, index) => ({
      title: chunks.title,
      sortOrder: index,
      inputText: chunks.text,
    })),
  }));

  let { data, error } = await supabase.rpc("create_summary_group_with_files", {
    p_files,
    include_cover_page: includeCoverPage,
    cover_page_logo_file_id: coverPageLogoUrl,
    cover_page_date: coverPageDate,
  });

  if (error) throw error;
  else console.log(data);
}

function withoutFileEnding(fileName: string) {
  return fileName.replace(/\.[^/.]+$/, "");
}
