import { useAuth } from "context/AuthProvider";
import { useState } from "react";
import { useDropzone } from "react-dropzone";
import { uploadLogo } from "services/supabase/supabase-frontend";

export function LogoUploader({
  onFileIdChange,
  disabled,
  show,
}: {
  onFileIdChange: (url: string | undefined) => void;
  disabled?: boolean;
  show?: boolean;
}) {
  const { user } = useAuth();
  const [file, setFile] = useState<File | null>(null);
  const onDropAccepted = async (newFiles: File[]) => {
    if (!newFiles[0] || !user) {
      return;
    }
    setFile(newFiles[0]);
    const logoFileId = await uploadLogo(user.id, newFiles[0]);
    onFileIdChange(logoFileId);
  };

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    onDropAccepted,
    accept: {
      // accept only image files:
      // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/file#accept

      "image/*": [".png", ".gif", ".jpg", ".jpeg"],
    },
  });
  if (!show) {
    return null;
  }

  return (
    <>
      {file ? (
        <div className="flex flex-col items-center justify-center w-1/2">
          {file.name}
          {!disabled && (
            <button
              onClick={() => {
                setFile(null);
                onFileIdChange(undefined);
              }}
              className="flex btn btn-ghost border-gray-100"
            >
              Change logo
            </button>
          )}
          {file && (
            <img
              src={URL.createObjectURL(file)}
              alt="Logo"
              className="w-1/2 h-1/2 object-contain"
            />
          )}
        </div>
      ) : (
        <div className={`flex flex-col items-center justify-center w-1/2`}>
          <div {...getRootProps({ className: "dropzone" })}>
            <input {...getInputProps()} />
            <button className="flex  justify-center w-full h-12 px-4 py-6 border-2 border-gray-300 rounded-md items-center text-center border-dashed hover:bg-gray-50 focus:outline-none">
              Click to upload logo for the summary
            </button>
          </div>
        </div>
      )}
    </>
  );
}
