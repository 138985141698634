function getAndRemoveFirstNonWhiteSpaceWordNotTimestamp(
  text: string
): [string, string] {
  const timestamp = /^(\d{2}:\d{2})/;
  const withoutTimestamp = text.trim().replace(timestamp, "").trim();
  return getAndRemoveFirstNonWhiteSpaceWord(withoutTimestamp);
}

function getAndRemoveFirstNonWhiteSpaceWord(text: string): [string, string] {
  const regex = /^\s*(\S+)/;
  const match = regex.exec(text);
  if (match) {
    const word = match[1] || "";
    const remainingStr = text.substring(match[0].length);
    return [word, remainingStr];
  }

  return ["", text];
}

export function isNumber(text: string) {
  if (text.trim() === "") {
    return false;
  }
  return !isNaN(Number(text));
}

export function getDepositionLineNumber(line: string): [number | null, string] {
  const [possibleLineNumber, rest] =
    getAndRemoveFirstNonWhiteSpaceWordNotTimestamp(line);

  if (isNumber(possibleLineNumber)) {
    return [Number(possibleLineNumber), rest];
  }
  return [null, rest];
}
