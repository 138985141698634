import config from "config";
import { useAuth } from "context/AuthProvider";
import { useTranslation } from "react-i18next";
import { BsSun } from "react-icons/bs";
import { HiOutlineQuestionMarkCircle } from "react-icons/hi2";
import { Link, Outlet } from "react-router-dom";
import { letters } from "utils/display";

const NavBar: React.FC = () => {
  const { user, signOut } = useAuth();
  const { t } = useTranslation();
  const name = user?.email;

  return (
    <>
      <div className="navbar bg-base-100">
        <div className="flex-1">
          {/* <a className="btn btn-ghost normal-case text-xl">daisyUI</a> */}
        </div>
        <div className="flex-none">
          <Link to={"/support"} className="btn btn-ghost btn-circle">
            <HiOutlineQuestionMarkCircle className="h-6 w-6" />
          </Link>

          <div className="dropdown dropdown-end">
            <label
              tabIndex={0}
              className="avatar placeholder cursor-pointer btn btn-ghost btn-circle"
            >
              <BsSun className="h-6 w-6" />
            </label>
            <ul
              tabIndex={0}
              className="dropdown-content bg-base-200 text-base-content rounded-t-box rounded-b-box top-px max-h-96 h-[70vh] w-52 overflow-y-auto shadow-2xl mt-16"
            >
              {config.themes.map((theme) => (
                <li key={theme}>
                  <div
                    className=""
                    data-set-theme={theme}
                    // data-act-className="outline"
                  >
                    <div
                      data-theme={theme}
                      className="bg-base-100 text-base-content w-full cursor-pointer font-sans"
                    >
                      <div className="grid grid-cols-5 grid-rows-3">
                        <div className="col-span-5 row-span-3 row-start-1 flex gap-1 py-3 px-4">
                          <div className="flex-grow text-sm font-bold">
                            {/* {theme} */}
                            {t(`theme.${theme}`)}
                          </div>{" "}
                          <div className="flex flex-shrink-0 flex-wrap gap-1">
                            <div className="bg-primary w-2 rounded"></div>{" "}
                            <div className="bg-secondary w-2 rounded"></div>{" "}
                            <div className="bg-accent w-2 rounded"></div>{" "}
                            <div className="bg-neutral w-2 rounded"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
          <div className="dropdown dropdown-end">
            <label
              tabIndex={0}
              className="avatar placeholder cursor-pointer btn btn-ghost btn-circle"
            >
              <div className=" rounded-full w-8 ring ring-neutral">
                <span className="text-xs">{letters(name ?? "")}</span>
              </div>
            </label>
            <ul
              tabIndex={0}
              className="menu menu-compact dropdown-content mt-3 p-2 shadow bg-base-100 rounded-box w-52"
            >
              <li>
                <Link to="/profile" className="justify-between">
                  {t("profile")}
                  <span className="badge">{t("new")}</span>
                </Link>
              </li>
              <li>
                <Link to="/settings" className="justify-between">
                  {t("settings")}
                </Link>
              </li>
              <li>
                <Link to="/admin" className="justify-between">
                  {t("admin")}
                </Link>
              </li>
              <li>
                <button onClick={signOut}>{t("logout")}</button>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="ml-2">
        <Outlet />
      </div>
    </>
  );
};

export default NavBar;
