import { useAuth } from "context/AuthProvider";
import { useEffect } from "react";
import { supabase } from "services/supabase/supabase-frontend";

export default function Home() {
  const { user } = useAuth();

  // useEffect(() => {

  //   fetchData();
  // }, []);
  const fetchData = async () => {
    const { data, error } = await supabase.functions.invoke("company", {
      body: { name: "bar" },
    });
    if (data.url) {
      window.location.href = data.url;
    }
    console.log("ok");
  };

  console.log(user?.company);
  const stripeStatus = user?.company?.stripe_subscription_status;
  return (
    <div>
      <h1 className="text-xl">Your company settings</h1>
      {stripeStatus === "active" ? (
        <p className="text-gray-500">Your subscription is active</p>
      ) : (
        <p className="text-gray-500">Your subscription is not active</p>
      )}
      <div className="mt-4">
        <button type="submit" className="btn btn-primary" onClick={fetchData}>
          Manage billing
        </button>
      </div>
    </div>
  );
}
