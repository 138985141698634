export interface Chunk {
  text: string;
  title: string;
}
export interface ChunkWithCount extends Chunk {
  wordCount: number;
  sentences: string[];
}
export interface PageWithLines {
  pageNumber: number;
  lines: string[];
}

export interface PageWithText {
  pageNumber: number;
  text: string;
}

export interface CurrentPage {
  pageNumber?: number;
  text: string;
  lastParsedLineNumber?: number;
}

export enum PrepFileStatus {
  IN_PROGRESS = "IN_PROGRESS",
  SUCCESS = "SUCCESS",
  ERROR = "Error",
}

export interface PrepFile {
  displayName: string;
  id: string;
  file: File;
  size: number;
  chunks?: Chunk[];
  status: PrepFileStatus;
  fileType: string;
  errorText?: string;
  isDeposition?: boolean;
}
