const config = {
  appUrl: "https://resipsaai.com",
  feedbackLink: "https://resipsaai.com/feedback",
  languages: [
    {
      name: "English",
      code: "en",
      flag: "🇺🇸",
    },
    {
      name: "Español",
      code: "es",
      flag: "🇪🇸",
    },
  ],
  themes: [
    "light",
    "dark",
    "cupcake",
    "bumblebee",
    "emerald",
    "corporate",
    "synthwave",
    "retro",
    "cyberpunk",
    "valentine",
    "halloween",
    "garden",
    "forest",
    "aqua",
    "lofi",
    "pastel",
    "fantasy",
    "wireframe",
    "black",
    "luxury",
    "dracula",
    "cmyk",
    "autumn",
    "business",
    "acid",
    "lemonade",
    "night",
    "coffee",
    "winter",
  ],
  platform: {
    logo: "logo.png",
    name: "Res Ipsa AI",
    linkedinLink: "https://www.linkedin.com/company/mallorca/",
  },
  mvpWorkspace: {
    name: "Billiam, LLC",
    slug: "billiam2",
    email: "kevin@billiam.company",
  },
  allowedEmails: [
    "kev.doran@gmail.com",
    "alicia@campbelllawllc.com",
    "john@campbelllawllc.com",
    'ebenezertesfaye@yahoo.com',
    "ebenezertesfaye11@gmail.com"
  ],
  allowedEmailDomains: ["campbelllawllc.com", "billiam.company"],
  maxFilesLimit: 10,
  bucketName: "resipsa",
};

export default config;
