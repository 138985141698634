import { BsFileEarmarkWordFill } from "react-icons/bs";
import { GrDocumentTxt } from "react-icons/gr";
import { TbSquareRoundedLetterD } from "react-icons/tb";
import { AiOutlineSearch } from "react-icons/ai";
import { PrepFileStatus, PrepFile } from "services/file-parsing/types";
import DisplayBytes from "components/DisplayBytes";

export default function FileTable({
  files,
  onRemoveFile,
  onNameChange,
  disabled,
}: {
  files: PrepFile[];
  onRemoveFile: (id: string) => void;
  onNameChange: (id: string, newName: string) => void;
  disabled?: boolean;
}) {
  return (
    <div className="overflow-x-auto w-full mt-4">
      <table className="table w-full">
        <thead>
          <tr>
            <th>File Name</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {files.map((file) => (
            <tr key={file.id}>
              <td>
                <div className="flex items-center space-x-3">
                  <div className="avatar">
                    <div
                      className={`avatar placeholder ${
                        file.status === PrepFileStatus.SUCCESS
                          ? "online"
                          : "offline"
                      }`}
                    >
                      <div className="bg-neutral-focus text-neutral-content rounded-full w-16">
                        <span className="text-xl">
                          {firstLettersOfWords(file.displayName)}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="w-full">
                    <div className="font-bold">
                      {disabled ? (
                        <span>{file.displayName}</span>
                      ) : (
                        <input
                          type="text"
                          placeholder="Type here"
                          className="input input-bordered w-full max-w-xs mb-2"
                          value={file.displayName}
                          disabled={disabled}
                          onChange={(e) => {
                            onNameChange(file.id, e.target.value as string);
                          }}
                        />
                      )}
                    </div>
                    <div className="flex flex-row">
                      <span className="badge badge-ghost badge-sm">
                        <DisplayBytes bytes={file.size} />
                      </span>
                      {file.fileType === "text/plain" && (
                        <GrDocumentTxt className="ml-2" />
                      )}
                      {file.chunks && file.chunks.length > 0 && (
                        <div className="ml-2">
                          <span className="badge badge-ghost badge-sm">
                            {file.chunks.length} summary sections
                          </span>
                        </div>
                      )}
                      {file.fileType ===
                        "application/vnd.openxmlformats-officedocument.wordprocessingml.document" && (
                        <BsFileEarmarkWordFill className="ml-2" />
                      )}
                    </div>
                    {file.status !== PrepFileStatus.IN_PROGRESS &&
                      !file.isDeposition && (
                        <div className="ml-2">
                          <span className="badge bg-red-100 badge-ghost badge-sm">
                            File not recognized as a deposition
                          </span>
                        </div>
                      )}
                  </div>
                </div>
              </td>
              <td>
                {file.status === PrepFileStatus.IN_PROGRESS && (
                  <>
                    <div className="flex flex-row">
                      <span>Checking file...</span>{" "}
                      <AiOutlineSearch className="animate-spin ml-2" />
                    </div>
                  </>
                )}
                {file.status === PrepFileStatus.ERROR && (
                  <>
                    <div>Error ❌ </div>
                    <div className="text-xs">{file.errorText}</div>
                  </>
                )}
                {file.status === PrepFileStatus.SUCCESS && <div>Ready ✅ </div>}
              </td>
              <td>
                <label>
                  {disabled ? (
                    ""
                  ) : (
                    <button
                      onClick={() => onRemoveFile(file.id)}
                      className="btn btn-secondary btn-xs hover:bg-red-700 hover:text-whyte"
                    >
                      Remove File?
                    </button>
                  )}
                </label>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

function firstLettersOfWords(word: string) {
  return word
    .split(" ")
    .map((w) => w[0])
    .splice(0, 3)
    .join("");
}
