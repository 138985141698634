import { Link } from "react-router-dom";

const Success = () => {
  return (
    <div className="items-center justify-center flex flex-col text-center mt-8 mb-4">
      <h1 className="text-2xl font-bold text-center">Create Summaries</h1>
      <div className="divider w-2/3 self-center" />
      <div className="alert shadow-lg w-3/5 mt-8 flex flex-col text-center justify-center">
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            className="stroke-info shrink-0 w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
            ></path>
          </svg>
          <h3 className="font-bold">
            Congratulations! You are registered with Res Ipsa AI.
          </h3>
        </div>
        <div className="text-sm w-2/3">
          If you already uploaded depositions, by signing up, you've completed
          the process. The depositions have been uploaded. Your AI summaries
          will be ready in the next 24-48 hours.
        </div>
        <Link to="/dashboard">
          <button className="btn btn-primary mt-4">
            Create more summaries
          </button>
        </Link>
      </div>
    </div>
  );
};

export default Success;
